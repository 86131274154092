import React, { useState, useEffect } from 'react'
import api from './api'
import '../css/Login.css'

import Logo from '../images/logo.png'

import { useNavigate, useSearchParams, Link } from 'react-router-dom'

import {
    LockOutlined,
    UserOutlined,
    // QqOutlined
} from '@ant-design/icons'
import { Button, Form, Input, message } from 'antd'
import md5 from 'js-md5'

//  登陆布局
function Login(props) {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [search] = useSearchParams()
    const [data, setData] = useState({ botToken: null })

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)

        // const accessToken = searchParams.get('access_token')
        const state = searchParams.get('state')
        // if (accessToken) {
        //     const key = Date
        //     message.loading({ key, content: '正在登陆中...', })
        //     setLoading(true)
        //     setTimeout(() => {
        //         api.post('/api/v2/user/qqLogin', {
        //             access_token: accessToken,
        //         })
        //             .then((res) => {
        //                 if (res.data.code === 200) {
        //                     localStorage.setItem('n2yun_user', JSON.stringify(res.data.data.data))
        //                     props.handleChange({
        //                         token: res.data.data.data.token,
        //                         user: res.data.data.data,
        //                         login: true
        //                     })
        //                     message.success({ key, content: '登陆成功,欢迎回来' })
        //                     props.setCookie('n2yunToken', res.data.data.data.token)
        //                     if (searchParams.get('redirect_uri')) {
        //                         window.location.href = searchParams.get('redirect_uri')
        //                         return
        //                     }
        //                     navigate(state || "/", { replace: true })
        //                 } else {
        //                     message.error({ key, content: res.data.data.text })
        //                     setLoading(false)
        //                 }
        //             })
        //             .catch((error) => {
        //                 message.error('网络异常,无法连接服务器')
        //                 setLoading(false)
        //             })
        //     }, 10);
        //     return
        // }

        setTimeout(() => {
            if (props.state.token) {
                navigate(state || "/", { replace: true })
            }
        }, 200)


        //  人机验证
        window._dx.Captcha(document.getElementById('DX_demo'), {
            appId: 'discuzaeb1253dcaf5e02f57eb47422a654525', //appId，在控制台应用管理或应用配置模块获取
            apiServer: 'https://cap.dingxiang-inc.com',
            // apiServer域名地址在控制台页面->无感验证->应用管页面左上角获取，必须填写完整包括https://。
            style: 'inline',
            width: 300,
            success: token => {
                // 获取验证码token，用于后端校验，注意获取token若是sl开头的字符串，则是前端网络不通生成的降级token,请检查前端网络及apiServer地址。
                console.log(token);
                setData({ ...data, botToken: token })
            }
        });
    }, [])

    //  设置共享 Cookie

    //  跳转
    function loginAction() {
        var redirect = search.get("redirect") || "/"
        navigate(redirect, { replace: true })
    }

    //  提交登陆
    function onFinish(values) {
        // const searchParams = new URLSearchParams(window.location.search)
        // const accessToken = searchParams.get('access_token')

        if (!data.botToken) {
            message.warning('请完成人机验证')
            return
        }

        const key = Date
        message.loading({ key, content: '正在登陆中...', })
        setLoading(true)
        setTimeout(() => {
            api.post('/api/v2/user/login', {
                name: values.username,
                password: md5(values.password),
                // access_token: accessToken,
                botToken: data.botToken,
            })
                .then((res) => {
                    if (res.data.code === 200) {
                        localStorage.setItem('n2yun_user', JSON.stringify(res.data.data.data))
                        props.handleChange({
                            token: res.data.data.data.token,
                            user: res.data.data.data,
                            login: true
                        })
                        message.success({ key, content: '登陆成功,欢迎回来' })
                        props.setCookie('n2yunToken', res.data.data.data.token)
                        // if (searchParams.get('redirect_uri')) {
                        //     window.location.href = searchParams.get('redirect_uri')
                        //     return
                        // }
                        loginAction()
                    } else {
                        message.error({ key, content: res.data.data.text })
                        setLoading(false)
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                    setLoading(false)
                })
        }, 10);
    }

    // //  QQ快捷登录
    // function QQ() {
    //     let url = search.get("redirect") || "/"
    //     const link = document.createElement('a')
    //     link.href = 'https://graph.qq.com/oauth2.0/authorize?response_type=token&client_id=102039079&redirect_uri=https://app.n2yun.com.cn/api/v2/qq_connect&state=' + url
    //     link.click()
    // }

    return (
        <div className='login'>
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <div className='logo'>
                    <Link to="/">
                        <img src={Logo} alt='logo'></img>
                    </Link>
                </div>

                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: '请输入用户名/邮箱地址!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名/邮箱地址" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码!',
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="密码"
                    />
                </Form.Item>

                {/* <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <a onClick={() => {
                            message.error('已关停此功能')
                        }} ><UserOutlined /> 子用户</a>
                    </Form.Item>
                    <a className="login-form-forgot" href="/forget_password">忘记密码?</a>
                </Form.Item> */}

                <Form.Item>
                    <div id="DX_demo" style={{ marginBottom: 20 }}></div>

                    <Button type="primary" htmlType="submit" className="login-form-button" loading={loading} disabled={!data.botToken}>登录</Button>
                    <a href="/register" className='login-form-register'>注册账户</a>
                    {/* <div>其他登录方式 <Button type="primary" onClick={QQ} shape="circle" icon={<QqOutlined />} size='small' /> </div> */}
                </Form.Item>
            </Form>
        </div>
    )
}





export default Login;