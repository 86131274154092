import React, { useEffect, useState, useCallback } from 'react'
import api from './api'
import s3 from './s3'

import { useParams, useNavigate } from 'react-router-dom'

import { InboxOutlined } from '@ant-design/icons'

import { Drawer, message, Select, Typography, Input, InputNumber, Button, Upload, Popconfirm, Result } from 'antd'

import { ProCard } from '@ant-design/pro-components'

import TurndownService from 'turndown'

const { Text, Title, Link } = Typography
const { TextArea } = Input
const { Dragger } = Upload


//  编辑器布局
function Edit(props) {
    const id = useParams()
    const navigate = useNavigate()
    const [data, setData] = useState({ category: 'server', type: 'free', exhibit: 'web', core: ['independent'], price: 0.01, resource: [], cores: [], script_rely: [], url: '', versions: [{ name: '默认版本', url: '' }] })
    const [dataKey, setDataKey] = useState(null)
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [update, setUpdate] = useState(null)
    const [height, setHeight] = useState(1300)

    useEffect(() => {
        if (id.id) {
            const nid = id.id
            api.get('/api/v2/article/', {
                params: {
                    nid: nid
                }
            })
                .then((res) => {
                    if (res.data.code === 200) {
                        const Data = res.data.data.data.Data
                        const turndownService = new TurndownService()
                        var markdown = turndownService.turndown(Data.html)
                        if (Data.markdown) {
                            markdown = Data.markdown
                        }
                        getHosting({ ...Data, markdown: markdown })
                    } else {
                        message.error(res.data.data.text)
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                })
        } else {
            let aticleData = localStorage.getItem('aticleData')
            if (aticleData) {
                getHosting(JSON.parse(aticleData))
            } else {
                getHosting({})
            }
        }
    }, [])


    useEffect(() => {
        if (!update) {
            return
        }
        setTimeout(() => {
            localStorage.setItem("aticleData", JSON.stringify(data))
        }, 100)
        setTimeout(() => {
            let edit_content = document.getElementsByClassName('edit_content')[0]
            setHeight(edit_content ? edit_content.offsetHeight : 1300)
        }, 200)
    }, [update])


    function getHosting(aticleData) {
        setTimeout(() => {
            api.get('/api/v2/user/get?name=resource')
                .then((res) => {
                    if (res.data.code === 200) {
                        let Data = res.data.data.data
                        setData({
                            ...data,
                            ...aticleData,
                            ...Data
                        })
                        setLoading(false)
                    } else {
                        message.error(res.data.data.text)
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                })
        }, 200)

        setTimeout(() => {
            let edit_content = document.getElementsByClassName('edit_content')[0]
            setHeight(edit_content ? edit_content.offsetHeight : 1300)
        }, 500)
    }


    //  文件上传
    const uploadProps = {
        name: 'file',
        maxCount: 1,
        customRequest(info) {
            if (info.file.size > 52457280) {
                message.error('文件大小超过50MB,无法上传')
                info.onError()
                return
            }
            s3.upload({
                type: 'file',
                file: info.file,
                name: info.file.name,
                size: info.file.size,
            }, (msg) => {
                let versions = [...data.versions]
                versions[dataKey] = {
                    ...versions[dataKey],
                    url: msg.url,
                }

                setData({
                    ...data,
                    versions: versions
                })
                setUpdate(Date.now())
                setOpen(false)
                info.onSuccess()
                message.success('上传成功')
            })
        },
    }

    //  内容修改
    const onChange = useCallback((e) => {
        let html = null
        if (e.target.name === 'markdown') {
            html = window.markdown.toHTML(e.target.value)
        }
        setUpdate(Date.now())
        if (html) {
            setData({
                ...data,
                [e.target.name]: e.target.value,
                html: html
            })
            return
        }
        setData({
            ...data,
            [e.target.name]: e.target.value,
        })
    }, [data])

    //  价格
    function onPriceChange(value) {
        setUpdate(Date.now())
        setData({
            ...data,
            price: value
        })
    }

    //  版块切换
    function handleChange(value) {
        setUpdate(Date.now())
        setData({
            ...data,
            category: value
        })
    }

    //  类型切换
    function handleTypeChange(value) {
        setUpdate(Date.now())
        setData({
            ...data,
            type: value
        })
    }

    //  展示位置
    function handleExhibitChange(value) {
        setUpdate(Date.now())
        setData({
            ...data,
            exhibit: value
        })
    }

    //  选择托管资源
    function handleValueChange(value) {
        setUpdate(Date.now())
        setData({
            ...data,
            value: value
        })
    }

    //  文件上传窗口
    const showDrawer = () => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }

    //  重置内容
    const confirm = (e) => {
        message.success('已经重置内容了!')
        setUpdate(Date.now())
        setData({ category: 'server', type: 'free', exhibit: 'web', resource: [], core: ['independent'], cores: [], script_rely: [], url: '', versions: [{ name: '默认版本', url: '' }] })
    }


    //  依赖项
    const handleRelyChange = (value) => {
        setData({
            ...data,
            rely: value
        })

        setUpdate(Date.now())
    }

    //  适配版本
    const handleCoreChange = (value) => {
        setData({
            ...data,
            core: value
        })

        setUpdate(Date.now())
    }

    //  修改版本
    function onVersionChange(key, e) {
        let versions = [...data.versions]

        versions[key] = {
            ...versions[key],
            [e.target.name]: e.target.value,
        }

        setData({
            ...data,
            versions: versions
        })

        setUpdate(Date.now())
    }

    //  删除版本
    function deleteVersion(key) {
        let versions = [...data.versions]

        if (versions.length === 1) {
            return message.error('无法删除最后一个')
        }

        versions.splice(key, 1)

        setData({
            ...data,
            versions: versions
        })

        setUpdate(Date.now())
    }

    //  新增版本
    function onClick() {
        let versions = [...data.versions]
        versions.push({ name: '未命名', url: '' })

        setData({
            ...data,
            versions: versions
        })

        setUpdate(Date.now())
    }


    function Send() {
        let Data = {}
        if (!data.title) {
            message.warning('请填写标题再发布文章')
            return
        }
        if (!data.html) {
            message.warning('内容似乎没更改,请更改内容再发布')
            return
        }
        let result = data.markdown.match(/!\[(.*?)\]\((.*?)\)/)
        if (result) {
            result = result[2]
        }

        Data.title = data.title
        Data.label = data.label
        Data.category = data.category
        Data.type = data.type
        Data.exhibit = data.exhibit
        Data.name = data.name
        Data.describe = data.describe
        Data.price = data.price
        Data.versions = data.versions
        Data.markdown = data.markdown
        Data.html = data.html
        Data.value = data.value
        Data.rely = data.rely ? data.rely : []
        Data.core = data.core
        Data.img = result
        Data.article_type = true

        api.post('/api/v2/article/', {
            data: JSON.stringify(Data),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    navigate('/')
                    message.success(res.data.data.text)
                    localStorage.removeItem('aticleData')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    function Save() {
        let Data = {}
        if (!data.title) {
            message.warning('请填写标题再发布文章')
            return
        }
        if (!data.html) {
            message.warning('内容似乎没更改,请更改内容再发布')
            return
        }
        let result = data.markdown.match(/!\[(.*?)\]\((.*?)\)/)
        if (result) {
            result = result[2]
        }

        Data.nid = id.id
        Data.title = data.title
        Data.label = data.label
        Data.category = data.category
        Data.type = data.type
        Data.exhibit = data.exhibit
        Data.name = data.name
        Data.describe = data.describe
        Data.price = data.price
        Data.versions = data.versions
        Data.markdown = data.markdown
        Data.html = data.html
        Data.value = data.value
        Data.rely = data.rely ? data.rely : []
        Data.core = data.core
        Data.img = result
        Data.article_type = false

        api.post('/api/v2/article/', {
            data: JSON.stringify(Data),
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setTimeout(() => {
                        navigate('/t/' + id.id)
                        message.success(res.data.data.text)
                        localStorage.removeItem('aticleData')
                    }, 500);
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }

    if (!props.verifyAy('admin')) {
        return (
            <Result
                status="403"
                title="403"
                subTitle="你没有这权限访问"
            />
        )
    }

    return (
        <div style={{ height: height }}>
            <div style={{ width: (window.innerWidth * 0.8) }} className='edit_content'>
                <Title level={3}>文章编辑器</Title>
                <ProCard title="信息配置" bordered headerBordered style={{ marginBottom: 20 }} loading={loading}>
                    <Input placeholder="标题" addonBefore='标题' style={{ width: 400 }} name='title' value={data.title} onChange={onChange} />
                    <Input placeholder="标签 (使用 , 来分隔)" addonBefore='标签' style={{ width: 300, marginLeft: 20 }} name='label' value={data.label} onChange={onChange} />
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <Select
                            style={{
                                width: 200,
                            }}
                            onChange={handleChange}
                            value={data.category}
                            options={[
                                {
                                    label: '官方区',
                                    options: [
                                        {
                                            label: '公告',
                                            value: 'notify',
                                        },
                                        {
                                            label: '付费资源',
                                            value: 'vip',
                                        },
                                    ],
                                },
                                {
                                    label: '资源区',
                                    options: [
                                        {
                                            label: '服务器资源',
                                            value: 'server',
                                        },
                                        {
                                            label: '外网开源',
                                            value: 'Extranet',
                                        },
                                        {
                                            label: '模组 | MOD',
                                            value: 'mods',
                                        },
                                        {
                                            label: '教程',
                                            value: 'tutorial',
                                        },
                                        {
                                            label: '服务端',
                                            value: 'Server',
                                        },
                                    ],
                                },
                            ]}
                        />

                        <Select
                            style={{
                                width: 150,
                                marginLeft: 20
                            }}
                            value={data.exhibit}
                            onChange={handleExhibitChange}
                            options={[
                                {
                                    label: '全部',
                                    value: 'all',
                                },
                                {
                                    label: 'NZadmin',
                                    value: 'nzadmin',
                                },
                                {
                                    label: '网站',
                                    value: 'web',
                                }
                            ]}
                        />

                        <Select
                            style={{
                                width: 150,
                                marginLeft: 20,
                                marginRight: 20
                            }}
                            value={data.type}
                            onChange={handleTypeChange}
                            options={[
                                {
                                    label: '免费内容',
                                    value: 'free',
                                },
                                {
                                    label: '付费内容',
                                    value: 'vip',
                                },
                            ]}
                        />

                        {data.type === 'vip' ? <>
                            <InputNumber addonBefore='价格' min={0.01} defaultValue={0.01} hidden={false} value={data.price} onChange={onPriceChange} />

                            <Select
                                style={{
                                    width: 400,
                                    marginLeft: 20,
                                    marginRight: 20
                                }}
                                onChange={handleValueChange}
                                options={data.resource}
                                value={data.value}
                            />
                        </> : null}
                    </div>
                </ProCard>



                {(data.exhibit === 'all' || data.exhibit === 'nzadmin') ?
                    <ProCard title="NZadmin配置" bordered headerBordered style={{ marginBottom: 20 }} loading={loading}>
                        <Input placeholder="资源代码" addonBefore='资源代码' style={{ width: 400 }} name='name' value={data.name} onChange={onChange} />
                        <Input placeholder="介绍" addonBefore='介绍' style={{ width: 400, marginLeft: 20 }} name='describe' value={data.describe} onChange={onChange} />
                    </ProCard>
                    : null}


                <ProCard title="文件版本号" bordered headerBordered style={{ marginBottom: 20 }} loading={loading}>
                    <div style={{ marginBottom: 20 }}>
                        <Button type="primary" onClick={onClick}>新增版本</Button>
                    </div>

                    {data.versions.map((i, key) => {
                        return (
                            <div key={key}>
                                <ProCard title={i.name} bordered headerBordered style={{ marginBottom: 20 }}>
                                    <Input placeholder="名称" style={{ width: 200, marginRight: 20 }} name='name' value={i.name} onChange={(e) => { onVersionChange(key, e) }} />
                                    <Input placeholder="文件链接" style={{ width: 400, marginRight: 20 }} name='url' value={i.url} onChange={(e) => { onVersionChange(key, e) }} />
                                    <Input placeholder="文件提取码" addonBefore='提取码' style={{ width: 200, marginRight: 20, display: i.url.indexOf('n2yun.com.cn') !== -1 ? 'none' : null }} name='code' value={i.code} onChange={(e) => { onVersionChange(key, e) }} />
                                    <Button onClick={() => {
                                        setDataKey(key)
                                        showDrawer()
                                    }}>上传文件</Button>
                                    <Button type="primary" style={{ marginLeft: 20 }} danger onClick={() => { deleteVersion(key) }} disabled={data.versions.length <= 1 ? true : false}>删除</Button>
                                </ProCard>
                            </div>
                        )
                    })}
                </ProCard>

                <ProCard title="依赖项" bordered headerBordered style={{ marginBottom: 20 }} loading={loading}>
                    <div style={{ marginBottom: 20 }}>
                        <Button type="primary" onClick={() => { navigate('/rely_edit') }}>新增依赖项</Button>
                    </div>
                    <Select
                        mode="multiple"
                        style={{
                            width: '100%',
                        }}
                        placeholder="选择当前插件所需要的依赖项"
                        onChange={handleRelyChange}
                        options={data.script_rely}
                        value={data.rely}
                    />
                </ProCard>

                <ProCard title="适配版本" bordered headerBordered style={{ marginBottom: 20 }} loading={loading}>
                    <Select
                        mode="multiple"
                        style={{
                            width: '100%',
                        }}
                        placeholder="选择当前插件适配的版本"
                        onChange={handleCoreChange}
                        options={data.cores}
                        value={data.core}
                    />
                </ProCard>

                <ProCard title="操作" bordered headerBordered style={{ marginBottom: 20 }} loading={loading}>
                    {id.id ?
                        <>
                            <Button type="primary" onClick={Save}>更新</Button>
                        </>
                        :
                        <>
                            <Button type="primary" onClick={Send}>发布</Button>
                            <Popconfirm
                                title="二次确认"
                                description="这将会清理当前所有数据并重新写内容的,是否确认重置内容?"
                                onConfirm={confirm}
                                okText="是"
                                cancelText="否"
                            >
                                <Button style={{ marginLeft: 20 }}>重置</Button>
                            </Popconfirm>
                        </>
                    }

                </ProCard>

                <ProCard title="内容" bordered headerBordered style={{ marginBottom: 20 }} loading={loading}>
                    <div style={{ width: '49.9%', float: 'left', height: 600 }}>
                        <TextArea style={{ height: 600 }} showCount rows={20} maxLength={10000} value={data.markdown} name='markdown' onChange={onChange} />
                    </div>
                    <div style={{ width: '49.9%', float: 'right', backgroundColor: 'rgba(187, 223, 255, 1.0)', height: 600 }} className='edit_html'><div style={{ padding: 20, backgroundColor: 'rgba(187, 223, 255, 1.0)', height: '100%' }} className='markdown-body' dangerouslySetInnerHTML={{ __html: data.html }}></div></div>
                </ProCard>
            </div>


            <Drawer
                title="上传文件"
                width={500}
                onClose={onClose}
                open={open}
            >
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">点击或拖拽文件到此区域上传</p>
                    <p className="ant-upload-hint">
                        仅支持大小为10MB以内的单个文件上传,请遵守中国法律法规,禁止上传色情等违法图片。
                    </p>
                </Dragger>
            </Drawer>
        </div>
    )
}

export default Edit